<script setup>
import { useRoute } from 'vue-router'
import EnvSelector from '@/components/proxy/EnvSelector.vue'

const route = useRoute()
const gameId = route?.query?.__game

function getGameUrl() {
  const gameUrlMap = { SoccerClash: 'soccer', StickTogether: 'stick', netto: 'netto', MinersBrawl: 'craft', MafiaRivals: 'mafia' }

  if (gameId) {
    return gameUrlMap[gameId]
  }

  return gameUrlMap.netto
}
</script>

<template>
  <crud-table
    :default-visible-columns="['HitThreeEvent', 'SeasonNo', 'Zone']"
    api="proxy/request"
    :versioned="false"
    :proxy-request-params="{
      uri: '/proxy/gameplay/hitthreeevents/',
      method: 'GET',
      env: 'dev'
    }"
    :columns-settings="{
      Zone: { width: 100 },
      SeasonNo: { width: 90 },
      Tournament: { cellEdit: false }
    }"
    :top-actions="{
      excelExport: false,
      importExcel: false,
      addNew: false
    }"
    :actions-on-list="{
      crudDelete: false
    }"
    :table-props="{
      'header-cell-class-name': 'font-medium bg-sky-50 gs-col-padding-mini text-sky-800'
    }"
    disable-multi-select-rows
  >
    <template #topLeft="{ searchFields }">
      <div class="w-20">
        <EnvSelector v-model:value-field="searchFields.env" />
      </div>
      <a
        :href="`https://${getGameUrl()}-prod.netto-api-gamesture.com/api/ap/?redir=hitthreeeventgroupspreview&pass=nhgriedjfgrj&hit_three_event_id=1000`"
        target="_blank"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600 pl-2"
      >
        Simulate Hit Three Event Groups
      </a>
      <a
        :href="`https://${getGameUrl()}-prod.netto-api-gamesture.com/api/ap/?redir=hitthreeeventgroupspreview&pass=nhgriedjfgrj&hit_three_event_id=1000&only_ranks=1`"
        target="_blank"
        class="text-blue-500 underline underline-offset-4 hover:text-blue-600 pl-2"
      >
        Simulate Hit Three Event Ranks
      </a>
    </template>
  </crud-table>
</template>
