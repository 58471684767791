<template>
  <crud-details-page
    api="rewards/card-event-reward"
    disable-top-margin
  >
    <template #form>
      <fields-col :sm="8">
        <!-- Label -->
        <crud-field-text
          api-field-name="Label"
          required
        />
      </fields-col>
      <fields-col
        v-for="i in 10"
        :key="i"
      >
        <section-divider>Milestone {{ i }}</section-divider>
        <fields-col :sm="8">
          <crud-field-number
            :api-field-name="`Reward${i}.Threshold`"
            label="Threshold"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- XpPct -->
          <crud-field-number
            :api-field-name="`Reward${i}.XpPct`"
            label="XpPct"
          />
          <!-- Energy -->
          <crud-field-number
            :api-field-name="`Reward${i}.Energy`"
            label="Energy"
          />
        </fields-col>
        <fields-col :sm="8">
          <!-- Gems -->
          <crud-field-number
            :api-field-name="`Reward${i}.Gems`"
            label="Gems"
          />
          <fields-col :sm="16">
            <crud-field-selector-item
              :api-field-name="`Reward${i}.Item`"
              label="Item"
            />
          </fields-col>
          <fields-col :sm="8">
            <crud-field-number
              :api-field-name="`Reward${i}.ItemAmount`"
              label="Item amount"
            />
          </fields-col>
        </fields-col>
      </fields-col>
    </template>
  </crud-details-page>
</template>
