import LogoNetto from '../../assets/logo_netto.png'

const DefaultMeta = {
  Title: '', // if empty string ('') will be overwritten by $route.meta.Title or $route.name
  TitlePrefix: '',
  TitleSuffix: ' : Project Netto : Admin Panel',
  FaviconFile: '/favicon.png' // name file in public folder,
}

const RouterMeta = {
  ...DefaultMeta,
  PageHeaderTitle: '', // if empty string ('') will be overwritten by DefaultMeta.Title
  DisableLayoutPageHeader: false, // if $route.meta.DisableLayoutPageHeader - disable only that current rote;
  IdParentSubmenu: [], // array unique ids of parent submenus,
  Entity: null
}

const LoginPageSettings = {
  Logo: LogoNetto,
  bgClass: 'bg-gradient-to-r from-black/75 via-black to-black/75',
  btnClass: 'text-white hover:bg-slate-900 border-slate-100 hover:border-slate-200 shadow-slate-500/50',
  btnIcon: 'akar-icons:google-fill',
  loadEnvs: true
}

const LayoutSettings = {
  pageHeader: {
    bgClass: 'bg-gradient-to-r from-black to-black/60 text-white font-normal',
    btnClass: 'text-white hover:bg-stone-700 active:bg-stone-500'
  },
  menu: {
    bgClass: 'bg-gradient-to-b from-black to-black/70 text-white',
    Logo: LogoNetto,
    title: 'Project Netto',
    titleClass: 'text-transparent bg-gradient-to-r from-stone-400 to-stone-200 font-black ',
    collapsedBtnClass: 'text-white hover:text-stone-300 active:bg-stone-700',
    textColor: '#ffffff',
    textActiveColor: '#FBBF24FF',
    hoverBgColor: 'rgb(64 64 64)',
    submenuBgColor: 'rgb(82 82 82)'
  },
  gameSelectorSettings: [
    // {id: '', name: '', env_variable: ''}
    { id: 'SoccerClash', name: 'Soccer Clash', env_variable: 'VITE_API_URL_SOCCER_CLASH' },
    { id: 'StickTogether', name: 'Stick Together', env_variable: 'VITE_API_URL_STICK_TOGETHER' },
    { id: 'netto', name: 'Project Netto', env_variable: 'VITE_API_URL' },
    { id: 'MinersBrawl', name: 'Miners Brawl', env_variable: 'VITE_API_URL_CRAFT_ROYALE' },
    { id: 'MafiaRivals', name: 'Mafia Rivals', env_variable: 'VITE_API_URL_CRAFT_MAFIA_RIVALS' },
    { id: 'CookingWars', name: 'Cooking Wars', env_variable: 'VITE_API_URL_C00KING_WARS' }
  ]
}
// WS settings:
// VITE_API_URL_SOCCER_CLASH=https://soccer-dev.netto-api-gamesture.com;VITE_API_URL_CRAFT_ROYALE=https://craft-dev.netto-api-gamesture.com;VITE_API_URL_STICK_TOGETHER=https://stick-dev.netto-api-gamesture.com;VITE_API_URL_PROD=https://dev.netto-api-gamesture.com;VITE_API_URL_DEV=https://dev.netto-api-gamesture.com;VITE_API_URL=https://dev.netto-api-gamesture.com;VITE_API_URL_CRAFT_MAFIA_RIVALS=https://mafia-dev.netto-api-gamesture.com
export { DefaultMeta, RouterMeta, LoginPageSettings, LayoutSettings }
