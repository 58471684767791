<script>
import CrudFieldSelectorOffer from '@/components/forms/crud-fields/CrudFieldSelectorOffer.vue'

export default {
  components: { CrudFieldSelectorOffer }
}
</script>

<template>
  <crud-details-page
    api="offers/multi-offer-group"
    disable-top-margin
  >
    <template #form>
      <!-- No -->
      <crud-field-number
        :sm="8"
        api-field-name="No"
        label="Price tier"
        required
        nullable
        vertical
      >
        <template #fieldAppend>
          <div class="text-black-600 pl-1">
            tier for everyone = <strong>0</strong>
          </div>
        </template>
      </crud-field-number>
      <!-- AssetOfferLogo -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetOfferLogo"
      />
      <!-- AssetSmall -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetSmall"
      />

      <!-- Name -->
      <crud-field-text
        :sm="8"
        api-field-name="Name"
        required
      />
      <!-- AssetHomeIcon -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetHomeIcon"
      />
      <!-- AssetMedium -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetMedium"
      />

      <!-- Type -->
      <crud-field-select
        :sm="8"
        api-field-name="Type"
        options-enum="enums.MultiOfferGroupType"
        required
      />
      <!-- AssetPopupBundle -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetPopupBundle"
      />
      <!-- AssetBig -->
      <crud-field-selector-files
        :sm="8"
        api-field-name="AssetBig"
      />

      <!-- HelpKey -->
      <crud-field-selector-client-strings
        :sm="8"
        api-field-name="HelpKey"
      />
      <section-divider>Extra fields</section-divider>
      <fields-col :sm="8">
        <!-- DurationHours -->
        <crud-field-number api-field-name="DurationHours" />
        <!-- DurationMinutes -->
        <crud-field-number api-field-name="DurationMinutes" />
        <!-- SeasonNo -->
        <crud-field-number api-field-name="SeasonNo" />
      </fields-col>
      <fields-col :sm="8">
        <!-- SeasonDayStart -->
        <crud-field-number api-field-name="SeasonDayStart" />
        <!-- SeasonHourStart -->
        <crud-field-number api-field-name="SeasonHourStart" />
        <!-- TriggerLevel -->
        <crud-field-number api-field-name="TriggerLevel" />
        <!-- NotificationString -->
        <crud-field-selector-client-strings api-field-name="NotificationString" />
      </fields-col>
      <fields-col :sm="8">
        <!-- IsFeatured -->
        <crud-field-switcher api-field-name="IsFeatured" />
        <!-- IsPromoPopup -->
        <crud-field-switcher api-field-name="IsPromoPopup" />
        <!-- IsScrollable -->
        <crud-field-switcher api-field-name="IsScrollable" />
        <!-- SendNotification -->
        <crud-field-switcher api-field-name="SendNotification" />
        <!-- IsOnlyForExternalStore -->
        <crud-field-switcher api-field-name="IsOnlyForExternalStore" />
      </fields-col>
      <section-divider>Offers</section-divider>
      <template
        v-for="i in 20"
        :key="i"
      >
        <CrudFieldSelectorOffer
          :sm="8"
          :api-field-name="`Offer${i}`"
        />
        <crud-field-selector-files
          :sm="8"
          :api-field-name="`AssetBackgrOffer${i}`"
        />
        <crud-field-switcher
          :sm="8"
          :api-field-name="`IsAssetBig${i}`"
        />
      </template>
    </template>
  </crud-details-page>
</template>
