import { defineAsyncComponent } from 'vue'
import { CrudRoute, MenuItem } from '!/router'

/**
 * create routes (with grouping)
 */

const dashRoutes = [
  CrudRoute.buildRoute('dashboard')
    .setPageComponents(() => import('@/pages/index/IndexPage.vue'))
    .getData()
]
const cacheRoutes = [
  CrudRoute.buildRoute(
    'cache',
    () => import('@/pages/tools/cache/IndexPage.vue'),
    'Cache',
    'Cache maintenance',
    'ic:baseline-cached'
  ).getData()
]

const rewardRoutes = [
  ...CrudRoute.buildRoutes(
    'player-rankings-rewards',
    () => import('@/pages/rewards/player-rankings/IndexPage.vue'),
    () => import('@/pages/rewards/player-rankings/DetailsPage.vue'),
    'Player ranking rewards',
    'Player ranking reward',
    'arcticons:rewards',
    'staticdata.PlayerRankingRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'league-rewards',
    () => import('@/pages/rewards/league-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/league-rewards/DetailsPage.vue'),
    'League rewards',
    'League reward',
    'arcticons:rewards',
    'staticdata.LeagueRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'facebook-rewards',
    () => import('@/pages/rewards/facebook-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/facebook-rewards/DetailsPage.vue'),
    'Facebook rewards',
    'Facebook reward',
    'arcticons:rewards',
    'staticdata.FacebookFriendsRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'friends-rewards',
    () => import('@/pages/rewards/friends-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/friends-rewards/DetailsPage.vue'),
    'Friends rewards',
    'Friends reward',
    'arcticons:rewards',
    'staticdata.FriendsRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'daily-login-rewards',
    () => import('@/pages/rewards/daily-login-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/daily-login-rewards/DetailsPage.vue'),
    'Daily login rewards',
    'Daily login reward',
    'arcticons:rewards',
    'staticdata.DailyLoginRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'deep-links',
    () => import('@/pages/rewards/deep-links/IndexPage.vue'),
    () => import('@/pages/rewards/deep-links/DetailsPage.vue'),
    'Deep links',
    'Deep link',
    'mdi:link-variant',
    'staticdata.DeepLinkEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'trophies',
    () => import('@/pages/rewards/trophies/IndexPage.vue'),
    () => import('@/pages/rewards/trophies/DetailsPage.vue'),
    'Trophies',
    'Trophy',
    'arcticons:rewards',
    'staticdata.TrophyEntity',
    defineAsyncComponent(() => import('@/components/selectors/TrophySelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gift-code-rewards',
    () => import('@/pages/rewards/gift-code-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/gift-code-rewards/DetailsPage.vue'),
    'Gift code rewards',
    'Gift code reward',
    'arcticons:rewards',
    'staticdata.GiftCodeRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'card-deck-rewards',
    () => import('@/pages/rewards/card-deck-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/card-deck-rewards/DetailsPage.vue'),
    'Card deck rewards',
    'Card deck reward',
    'mdi:cards',
    'staticdata.CardDeckRewardsEntity',
    defineAsyncComponent(() => import('@/components/selectors/CardDeckRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'quest-rewards',
    () => import('@/pages/rewards/quest-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/quest-rewards/DetailsPage.vue'),
    'Quest rewards',
    'Quest reward',
    'arcticons:rewards',
    'staticdata.QuestRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/QuestRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'tournament-rewards',
    () => import('@/pages/rewards/tournament-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/tournament-rewards/DetailsPage.vue'),
    'Tournament rewards',
    'Tournament reward',
    'arcticons:rewards',
    'staticdata.TournamentRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'arithmetic-run-rewards',
    () => import('@/pages/rewards/arithmetic-run-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/arithmetic-run-rewards/DetailsPage.vue'),
    'Arithmetic run rewards',
    'Arithmetic run reward',
    'mdi:run',
    'staticdata.ArithmeticRunRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'wheel-milestone-rewards',
    () => import('@/pages/rewards/wheel-milestone-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/wheel-milestone-rewards/DetailsPage.vue'),
    'Wheel milestone rewards',
    'Wheel milestone reward',
    'arcticons:rewards',
    'staticdata.WheelMilestoneRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'hit-three-event-rewards',
    () => import('@/pages/rewards/hit-three-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/hit-three-event-rewards/DetailsPage.vue'),
    'Hit three event rewards',
    'Hit three event reward',
    'arcticons:rewards',
    'staticdata.HitThreeRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'hit-three-event-global-rewards',
    () => import('@/pages/rewards/hit-three-event-global-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/hit-three-event-global-rewards/DetailsPage.vue'),
    'Hit three event global rewards',
    'Hit three event global reward',
    'arcticons:rewards',
    'staticdata.HitThreeGlobalRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventGlobalRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lottery-event-rewards',
    () => import('@/pages/rewards/lottery-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/lottery-event-rewards/DetailsPage.vue'),
    'Lottery event rewards',
    'Lottery event reward',
    'arcticons:rewards',
    'staticdata.LotteryEventRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/LotteryEventRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lottery-event-global-rewards',
    () => import('@/pages/rewards/lottery-event-global-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/lottery-event-global-rewards/DetailsPage.vue'),
    'Lottery event global rewards',
    'Lottery event global reward',
    'arcticons:rewards',
    'staticdata.LotteryEventGlobalRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/LotteryEventGlobalRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'card-event-rewards',
    () => import('@/pages/rewards/card-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/card-event-rewards/DetailsPage.vue'),
    'Card event rewards',
    'Card event reward',
    'mdi:cards',
    'staticdata.CardEventRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/CardEventRewardSelector.vue'))
  ).getData()
]

const eventsRoutes = [
  ...CrudRoute.buildRoutes(
    'rush-events',
    () => import('@/pages/events/rush-events/IndexPage.vue'),
    () => import('@/pages/events/rush-events/DetailsPage.vue'),
    'Rush events',
    'Rush event',
    'mdi:sword-cross',
    'staticdata.RushEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/RushEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'rush-event-rewards',
    () => import('@/pages/events/rush-event-rewards/IndexPage.vue'),
    () => import('@/pages/events/rush-event-rewards/DetailsPage.vue'),
    'Rush event rewards',
    'Rush event reward',
    'mdi:shield-sword-outline',
    'staticdata.RushEventRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'card-events',
    () => import('@/pages/events/card-events/IndexPage.vue'),
    () => import('@/pages/events/card-events/DetailsPage.vue'),
    'Card events',
    'Card event',
    'mdi:cards',
    'staticdata.CardEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/CardEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'card-exchange-events',
    () => import('@/pages/events/card-exchange-events/IndexPage.vue'),
    () => import('@/pages/events/card-exchange-events/DetailsPage.vue'),
    'Card exchange events',
    'Card exchange event',
    'mdi:cards',
    'staticdata.CardExchangeEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/CardExchangeEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lottery-events',
    () => import('@/pages/events/lottery-events/IndexPage.vue'),
    () => import('@/pages/events/lottery-events/DetailsPage.vue'),
    'Lottery events',
    'Lottery event',
    'mdi:cow',
    'staticdata.LotteryEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/LotteryEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'tournaments',
    () => import('@/pages/events/tournaments/IndexPage.vue'),
    () => import('@/pages/events/tournaments/DetailsPage.vue'),
    'Tournaments',
    'Tournament',
    'ph:tree-structure',
    'staticdata.TournamentEntity',
    defineAsyncComponent(() => import('@/components/selectors/TournamentSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'arithmetic-runs',
    () => import('@/pages/events/arithmetic-runs/IndexPage.vue'),
    () => import('@/pages/events/arithmetic-runs/DetailsPage.vue'),
    'Arithmetic runs',
    'Arithmetic run',
    'mdi:run',
    'staticdata.ArithmeticRunEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'arithmetic-run-obstacles',
    () => import('@/pages/events/arithmetic-run-obstacles/IndexPage.vue'),
    () => import('@/pages/events/arithmetic-run-obstacles/DetailsPage.vue'),
    'Arithmetic run obstacles',
    'Arithmetic run obstacle',
    'mdi:run',
    'staticdata.ArithmeticRunObstacleEntity',
    defineAsyncComponent(() => import('@/components/selectors/ArithmeticRunObstacleSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'hit-three-events',
    () => import('@/pages/events/hit-three-events/IndexPage.vue'),
    () => import('@/pages/events/hit-three-events/DetailsPage.vue'),
    'Hit three events',
    'Hit three event',
    'mdi:cow',
    'staticdata.HitThreeEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventSelector.vue'))
  ).getData()
]

const itemsRoutes = [
  ...CrudRoute.buildRoutes(
    'items',
    () => import('@/pages/items/items/IndexPage.vue'),
    () => import('@/pages/items/items/DetailsPage.vue'),
    'Items',
    'Item',
    'majesticons:t-shirt-line',
    'staticdata.ItemEntity',
    defineAsyncComponent(() => import('@/components/selectors/ItemSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'decks',
    () => import('@/pages/items/decks/IndexPage.vue'),
    () => import('@/pages/items/decks/DetailsPage.vue'),
    'Decks',
    'Deck',
    'mdi:cards',
    'staticdata.CardDeckEntity',
    defineAsyncComponent(() => import('@/components/selectors/CardDeckSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'external-skins',
    () => import('@/pages/items/external-skins/IndexPage.vue'),
    () => import('@/pages/items/external-skins/DetailsPage.vue'),
    'Skins (Stick/Craft/Mafia)',
    'External skin (Stick/Craft/Mafia)',
    'mdi:cube-unfolded',
    'staticdata.ExternalSkinEntity',
    defineAsyncComponent(() => import('@/components/selectors/ExternalSkinsSelector.vue'))
  ).getData()
]

const offersRoutes = [
  ...CrudRoute.buildRoutes(
    'offers',
    () => import('@/pages/offers/offers/IndexPage.vue'),
    () => import('@/pages/offers/offers/DetailsPage.vue'),
    'Offers',
    'Offer',
    'ic:outline-attach-money',
    'staticdata.OfferEntity',
    defineAsyncComponent(() => import('@/components/selectors/OfferSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'multi-offer-groups',
    () => import('@/pages/offers/multi-offer-groups/IndexPage.vue'),
    () => import('@/pages/offers/multi-offer-groups/DetailsPage.vue'),
    'Multi offer groups',
    'Multi offer group',
    'ic:outline-attach-money',
    'staticdata.MultiOfferGroupEntity',
    defineAsyncComponent(() => import('@/components/selectors/MultiOfferGroupsSelector.vue'))
  ).getData()
]

const energyPacksRoutes = [
  ...CrudRoute.buildRoutes(
    'energy-packs',
    () => import('@/pages/energy-packs/IndexPage.vue'),
    () => import('@/pages/energy-packs/DetailsPage.vue'),
    'Energy packs',
    'Energy pack',
    'mdi:cart-arrow-down',
    'staticdata.ShopEntity' // TODO CHECK
  ).getData()
]

const seasonRoutes = [
  ...CrudRoute.buildRoutes(
    'seasons',
    () => import('@/pages/seasons/season-settings/IndexPage.vue'),
    () => import('@/pages/seasons/season-settings/DetailsPage.vue'),
    'Seasons',
    'Season',
    'material-symbols:calendar-month-outline',
    'staticdata.SeasonEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-calendars',
    () => import('@/pages/seasons/season-calendars/IndexPage.vue'),
    () => import('@/pages/seasons/season-calendars/DetailsPage.vue'),
    'Season calendars',
    'Season calendar',
    'mdi:calendar-time',
    'staticdata.SeasonCalendarEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-passes',
    () => import('@/pages/seasons/season-passes/IndexPage.vue'),
    () => import('@/pages/seasons/season-passes/DetailsPage.vue'),
    'Season passes',
    'Season pass',
    'material-symbols:calendar-month-outline',
    'staticdata.SeasonPassEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-migration',
    () => import('@/pages/seasons/season-migration/IndexPage.vue'),
    () => import('@/pages/seasons/season-migration/DetailsPage.vue'),
    'Migration settings',
    'Migration settings',
    'mdi:people',
    'staticdata.MigrationSettingsEntity'
  ).getData()
]

const settingsRoutes = [
  ...CrudRoute.buildRoutes(
    'game-settings',
    () => import('@/pages/settings/game-settings/IndexPage.vue'),
    () => import('@/pages/settings/game-settings/DetailsPage.vue'),
    'Game settings',
    'Game setting',
    'arcticons:settings',
    'staticdata.GameSettingsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'change-logs',
    () => import('@/pages/settings/change-logs/IndexPage.vue'),
    () => import('@/pages/settings/change-logs/DetailsPage.vue'),
    'Changelogs',
    'Changelog',
    'arcticons:settings',
    'admin.ChangelogEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'guild-search-options',
    () => import('@/pages/settings/guild-search-options/IndexPage.vue'),
    () => import('@/pages/settings/guild-search-options/DetailsPage.vue'),
    'Guild search options',
    'Guild search option',
    'arcticons:settings',
    'staticdata.GuildSearchOptionEntity'
  ).getData()
]

const divisionSettingsRoutes = [
  ...CrudRoute.buildRoutes(
    'division-levels',
    () => import('@/pages/division-settings/division-levels/IndexPage.vue'),
    () => import('@/pages/division-settings/division-levels/DetailsPage.vue'),
    'Division levels',
    'Division level',
    'arcticons:settings',
    'staticdata.DivisionLevelEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'battle-rewards',
    () => import('@/pages/division-settings/battle-rewards/IndexPage.vue'),
    () => import('@/pages/division-settings/battle-rewards/DetailsPage.vue'),
    'Battle rewards',
    'Battle reward',
    'arcticons:settings',
    'staticdata.DivisionBattleRewardsEntity'
  ).getData()
]

const toolsRoutes = [
  ...CrudRoute.buildRoutes(
    'template-global-messages',
    () => import('@/pages/tools/template-global-messages/IndexPage.vue'),
    () => import('@/pages/tools/template-global-messages/DetailsPage.vue'),
    'Template global messages',
    'Template global message',
    'material-symbols:mail-outline',
    'staticdata.GlobalMessageTemplateEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'global-messages',
    () => import('@/pages/tools/global-messages/IndexPage.vue'),
    () => import('@/pages/tools/global-messages/DetailsPage.vue'),
    'Global messages',
    'Global message',
    'material-symbols:mail-outline',
    'staticdata.GlobalMessageEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'personal-messages',
    () => import('@/pages/tools/personal-messages/IndexPage.vue'),
    () => import('@/pages/tools/personal-messages/DetailsPage.vue'),
    'Personal messages',
    'Personal message',
    'material-symbols:mail-outline',
    'admin.PersonalMessageEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'group-notifications',
    () => import('@/pages/tools/group-notifications/IndexPage.vue'),
    () => import('@/pages/tools/group-notifications/DetailsPage.vue'),
    'Group notifications',
    'Group notification',
    'material-symbols:notification-important-outline',
    'staticdata.GroupNotificationEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'console-commands',
    () => import('@/pages/tools/console-commands/IndexPage.vue'),
    () => import('@/pages/tools/console-commands/DetailsPage.vue'),
    'Console Commands',
    'Console Command',
    'material-symbols:mail-outline',
    'admin.ConsoleCommandEntity'
  ).getData()
]

const gamePlayRoutes = [
  CrudRoute.buildRoute(
    'gameplay-payment-logs',
    () => import('@/pages/gameplay/payment-logs/IndexPage.vue'),
    'Payment logs',
    'Payment logs',
    'majesticons:money-hand-line',
    'gameplay.PaymentLogEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-divisions',
    () => import('@/pages/gameplay/divisions/IndexPage.vue'),
    'Divisions',
    'Divisions',
    'ic:outline-grid-view'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-guild-list',
    () => import('@/pages/gameplay/guild-list/IndexPage.vue'),
    () => import('@/pages/gameplay/guild-list/DetailsPage.vue'),
    'Guild lists',
    'Guild',
    'mdi:list-box-outline',
    'gameplay.GuildEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-new-players',
    () => import('@/pages/gameplay/new-players/IndexPage.vue'),
    'New players 24h',
    'New players 24h',
    'mdi:person-multiple-add-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-welcome-players',
    () => import('@/pages/gameplay/welcome-players/IndexPage.vue'),
    'Welcome players list',
    'Welcome players list',
    'mdi:person-multiple-add-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-pts-ranking',
    () => import('@/pages/gameplay/season-pts-ranking/IndexPage.vue'),
    'Player season points ranking',
    'Season points ranking',
    'material-symbols:bubble-chart-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-pts-groups',
    () => import('@/pages/gameplay/season-pts-groups/IndexPage.vue'),
    'Season points groups',
    'Season points groups',
    'material-symbols:bubble-chart'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-guild-search-test',
    () => import('@/pages/gameplay/guild-search-test/IndexPage.vue'),
    'Guild search test',
    'Guild search test',
    'ic:outline-manage-search'
  )
    .setQuery({
      c_sea: {
        zone: 'Europe',
        country: 'PL',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      }
    })
    .getData(),
  CrudRoute.buildRoute(
    'promo-energy-hack-log',
    () => import('@/pages/gameplay/promo-energy-hack-log/IndexPage.vue'),
    'Promo Energy Hack Log',
    'Promo Energy Hack Log',
    'material-symbols:bubble-chart',
    'gameplay.PromoEnergyHackLogEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-player-device-ids',
    () => import('@/pages/gameplay/player-device-ids/IndexPage.vue'),
    'Player Device Ids',
    'Player Device Ids',
    'material-symbols:smartphone'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-players',
    () => import('@/pages/gameplay/players/IndexPage.vue'),
    () => import('@/pages/gameplay/players/DetailsPage.vue'),
    'Players',
    'Player',
    'mdi:person-multiple',
    'gameplay.PlayerEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-autoinvite-preview',
    () => import('@/pages/gameplay/autoinvite-preview/IndexPage.vue'),
    'Autoinvite preview',
    'Autoinvite preview',
    'ic:twotone-auto-mode'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-autoinvite-logs',
    () => import('@/pages/gameplay/autoinvite-logs/IndexPage.vue'),
    'Autoinvite logs on prod',
    'Autoinvite logs',
    'icon-park-outline:upload-logs'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-chat-preview',
    () => import('@/pages/gameplay/chat/IndexPage.vue'),
    'Chat preview',
    'Chat preview',
    'material-symbols:chat-outline'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-tournaments',
    () => import('@/pages/gameplay/tournaments/IndexPage.vue'),
    () => import('@/pages/gameplay/tournaments/DetailsPage.vue'),
    'Tournaments history',
    'Tournament history',
    'mdi:tournament',
    'gameplay.TournamentStateEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-rush-events',
    () => import('@/pages/gameplay/rush-events/IndexPage.vue'),
    () => import('@/pages/gameplay/rush-events/DetailsPage.vue'),
    'Rush events history',
    'Rush event history',
    'charm:sword',
    'gameplay.RushEventStateEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-hit-three-events',
    () => import('@/pages/gameplay/hit-three-events/IndexPage.vue'),
    () => import('@/pages/gameplay/hit-three-events/DetailsPage.vue'),
    'Hit Three Event history',
    'Hit Three Event history',
    'mdi:cow',
    'gameplay.HitThreeEventInitEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-lottery-events',
    () => import('@/pages/gameplay/lottery-events/IndexPage.vue'),
    () => import('@/pages/gameplay/lottery-events/DetailsPage.vue'),
    'Lottery Event history',
    'Lottery Event history',
    'mdi:cow',
    'gameplay.LotteryStateEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-avatars-list',
    () => import('@/pages/gameplay/avatars-list/IndexPage.vue'),
    'Avatars list',
    'Avatars list',
    'mdi:people'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-calendar',
    () => import('@/pages/gameplay/season-calendar/IndexPage.vue'),
    'Season day calendar',
    'Season day calendar',
    'mdi:calendar'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-player-resources-logs',
    () => import('@/pages/gameplay/player-resources-logs/IndexPage.vue'),
    'Player Resources Logs',
    'Player Resources Logs',
    'mdi:person-multiple-add-outline'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-hit-three-events',
    () => import('@/pages/gameplay/hit-three-events/IndexPage.vue'),
    () => import('@/pages/gameplay/hit-three-events/DetailsPage.vue'),
    'Hit Three Event history',
    'Hit Three Event history',
    'mdi:cow',
    'gameplay.HitThreeEventStateEntity'
  ).getData()
]

const chestsRoutes = [
  ...CrudRoute.buildRoutes(
    'chests',
    () => import('@/pages/chests/chests/IndexPage.vue'),
    () => import('@/pages/chests/chests/DetailsPage.vue'),
    'Chests',
    'Chest',
    'game-icons:open-chest',
    'staticdata.ChestEntity',
    defineAsyncComponent(() => import('@/components/selectors/ChestSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'packs',
    () => import('@/pages/chests/packs/IndexPage.vue'),
    () => import('@/pages/chests/packs/DetailsPage.vue'),
    'Packs',
    'Pack',
    'mdi:cards',
    'staticdata.ChestEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lotteries',
    () => import('@/pages/chests/lotteries/IndexPage.vue'),
    () => import('@/pages/chests/lotteries/DetailsPage.vue'),
    'Lotteries',
    'Lottery',
    'game-icons:open-chest',
    'staticdata.ChestEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'wheels',
    () => import('@/pages/chests/wheels/IndexPage.vue'),
    () => import('@/pages/chests/wheels/DetailsPage.vue'),
    'Wheels',
    'Wheel',
    'fa6-solid:spinner',
    'staticdata.ChestEntity'
  ).getData()
]

const questsRoutes = [
  ...CrudRoute.buildRoutes(
    'quests',
    () => import('@/pages/quests/quests/IndexPage.vue'),
    () => import('@/pages/quests/quests/DetailsPage.vue'),
    'Quests',
    'Quest',
    'uil:game-structure',
    'staticdata.QuestEntity',
    defineAsyncComponent(() => import('@/components/selectors/QuestSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'quest-groups',
    () => import('@/pages/quests/quest-groups/IndexPage.vue'),
    () => import('@/pages/quests/quest-groups/DetailsPage.vue'),
    'Quest groups',
    'Quest group',
    'uil:game-structure',
    'staticdata.QuestGroupEntity',
    defineAsyncComponent(() => import('@/components/selectors/QuestGroupSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'quest-type-configs',
    () => import('@/pages/quests/quest-type-configs/IndexPage.vue'),
    () => import('@/pages/quests/quest-type-configs/DetailsPage.vue'),
    'Quest type configs',
    'Quest type config',
    'uil:game-structure',
    'staticdata.QuestTypeConfigEntity'
  ).getData()
]

export default () => {
  const items = [
    MenuItem.build('dashboard', dashRoutes).getData(),
    MenuItem.build('cache-btn')
      .setMenuItemComponent(defineAsyncComponent(() => import('@/pages/tools/cache/MenuItem.vue')))
      .getData(),
    MenuItem.build('cache', cacheRoutes).setIsSubMenu(false).getData(),
    MenuItem.build('seasons', seasonRoutes, 'mdi:calendar-time', 'Season settings').getData(),
    MenuItem.build('gameplay', gamePlayRoutes, 'mdi:gamepad-variant-outline', 'Gameplay stats').getData(),
    MenuItem.build('rewards', rewardRoutes, 'arcticons:rewards', 'Rewards').setCssClass('stroke-2').getData(),
    MenuItem.build('events', eventsRoutes, 'mdi:calendar-clock', 'Events').getData(),
    MenuItem.build('items', itemsRoutes, 'majesticons:t-shirt-line', 'Items').getData(),
    MenuItem.build('chests', chestsRoutes, 'game-icons:open-chest', 'Chests').getData(),
    MenuItem.build('quests', questsRoutes, 'uil:game-structure', 'Quests').getData(),
    MenuItem.build('offers', offersRoutes, 'ic:outline-attach-money', 'Offers').getData(),
    MenuItem.build('energy-packs', energyPacksRoutes, 'mdi:cart-arrow-down', 'Energy packs').setIsSubMenu(false).getData(),
    MenuItem.build('settings', settingsRoutes).setPriority(0).getData(),
    MenuItem.build('division-settings', divisionSettingsRoutes, 'arcticons:settings', 'Division settings')
      .setCssClass('stroke-2')
      .getData(),
    MenuItem.build('tools', toolsRoutes).setPriority(0).getData()
  ]

  return items
}
