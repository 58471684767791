<script>
import BattleScorePreview from '@/components/shared/BattleScorePreview.vue'

export default {
  components: { BattleScorePreview },
  setup() {
    return {
      previewActions: {}
    }
  },
  data() {
    return {
      externalData: {},
      envApi: '',
      endPoint: '',
      loading: {},
      dialogBattleVisible: false
    }
  },
  methods: {
    loadData() {
      const envApi = this.$store.getters['auth/envs']?.[this.$route.query?.env]
      if (!(this.$utils.isDevMode() && this.$route.query?.env !== 'local')) {
        this.envApi = envApi
      }
      this.endPoint = `/proxy/gameplay/tournament/${this.$route.params?.id}/`
      this.$axios
        .get(envApi + this.endPoint, { params: { json: 1 } })
        .then(({ data }) => {
          this.externalData = data
        })
        .catch(this.$utils.catchError)
    },
    setTitle(apiLabel, data) {
      return `${(this.$route.query?.env || '').toUpperCase()} : Tournament results :  ${data?.name || ''}`
    }
  }
}
</script>

<template>
  <crud-details-page
    :versioned="false"
    :actions="{
      crudSave: false,
      crudDuplicate: false,
      crudNewVersion: false,
      crudUsage: false,
      crudDelete: false
    }"
    disable-top-margin
    :external-data="externalData"
    :render-title="setTitle"
    :mobile-single-column="false"
    @is-access="loadData"
  >
    <template #form>
      <h1 v-if="externalData?.s?.length">
        Date {{ externalData.s }} - {{ externalData.e }}
      </h1>
      <el-scrollbar class="w-full">
        <table class="gs-font-scaled whitespace-nowrap align-top">
          <template v-if="externalData?.leagues?.length">
            <section-divider>League Phase</section-divider>
            <template
              v-for="(league, index) in externalData.leagues"
              :key="index"
            >
              <tr>
                <td
                  colspan="4"
                  class="gs-related-py-md font-bold"
                >
                  League #{{ league.no }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(matchday, matchdayIndex) in league.matchdays"
                  :key="matchdayIndex"
                  class="gs-related-px-sm align-top"
                >
                  <div class="font-related-sm gs-related-py-xs font-semibold">
                    Matchday: {{ matchday.matchday }}
                  </div>
                  <a
                    v-for="(battle, indexBattle) in matchday.battles"
                    :key="indexBattle"
                    class="block cursor-pointer"
                    @click="previewActions?.previewBattle?.(battle)"
                  >
                    <div class="inline-block pb-1.5 pl-2 text-sky-600 hover:text-sky-500">
                      <div>Pair no: {{ battle.pair_no }}</div>
                      <div>
                        <div
                          v-for="n in 2"
                          :key="n"
                        >
                          <b>{{ battle[`guild${n}_id`] }} {{ battle[`guild${n}_name`] }} {{ battle[`guild${n}_country`] }}</b>
                          Pow: {{ battle[`guild${n}_power`] }} Rank: {{ battle[`guild${n}_finalrank`] }} WonLines: {{ battle[`guild${n}_won_lines`] }} Zone: {{ battle[`guild${n}_zone`] }}
                        </div>
                      </div>
                      <div>Skirmish time UTC: {{ battle.skirmish_start }}</div>
                      <el-divider class="my-0" />
                    </div>
                  </a>
                </td>
              </tr>
              <tr>
                <el-table
                  :data="league.table"
                  border
                  height="250"
                >
                  <el-table-column
                    fixed
                    prop="id"
                    label="ID"
                    width="100"
                  />
                  <el-table-column
                    prop="name"
                    label="Name"
                    width="150"
                  />
                  <el-table-column
                    prop="zone"
                    label="Zone"
                    width="100"
                  />
                  <el-table-column
                    prop="power"
                    label="Power"
                    width="100"
                  />
                  <el-table-column
                    prop="wins"
                    label="Won"
                    width="100"
                  />
                  <el-table-column
                    prop="goals_scored"
                    label="Goals Scored"
                    width="200"
                  />
                  <el-table-column
                    prop="goals_against"
                    label="Goals Conceded"
                    width="200"
                  />
                </el-table>
              </tr>
            </template>
          </template>

          <template v-if="externalData?.groups?.length">
            <section-divider>PlayOff Phase</section-divider>
            <template
              v-for="(group, index) in externalData.groups"
              :key="index"
            >
              <tr>
                <td
                  colspan="4"
                  class="gs-related-py-md font-bold"
                >
                  Group #{{ group.no }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(stage, stageIndex) in group.stages"
                  :key="stageIndex"
                  class="gs-related-px-sm align-top"
                >
                  <div class="font-related-sm gs-related-py-xs font-semibold">
                    Stage: {{ stage.stage }}
                  </div>
                  <a
                    v-for="(battle, indexBattle) in stage.battles"
                    :key="indexBattle"
                    class="block cursor-pointer"
                    @click="previewActions?.previewBattle?.(battle)"
                  >
                    <div class="inline-block pb-1.5 pl-2 text-sky-600 hover:text-sky-500">
                      <div>Pair no: {{ battle.pair_no }}</div>
                      <div>
                        <div
                          v-for="n in 2"
                          :key="n"
                        >
                          <b>{{ battle[`guild${n}_id`] }} {{ battle[`guild${n}_name`] }} {{ battle[`guild${n}_country`] }}</b>
                          Pow: {{ battle[`guild${n}_power`] }} Rank: {{ battle[`guild${n}_finalrank`] }} WonLines: {{ battle[`guild${n}_won_lines`] }} Zone: {{ battle[`guild${n}_zone`] }}
                        </div>
                      </div>
                      <div>Skirmish time UTC: {{ battle.skirmish_start }}</div>
                      <el-divider class="my-0" />
                    </div>
                  </a>
                </td>
              </tr>
            </template>
          </template>
        </table>
      </el-scrollbar>
    </template>
    <template #drawers>
      <BattleScorePreview @bind-preview-battle-action="previewActions.previewBattle = $event" />
    </template>
  </crud-details-page>
</template>
